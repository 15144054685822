<template>
  <div class="box">
    <div class="bannerBox">
      <img class="banner" src="/subject/newReport/banner.jpg" alt="">
      <img class="logo" @click="goBank" src="/subject/newReport/logo.png" alt="">
    </div>
    <div class="content">
      <div class="part part_1">
        <img src="/subject/newReport/title1.png" alt="">
        <div class="p1_box">
          <video class="video" v-if="!ckplayerObject.live" :src="isVideo" controls="controls" autoplay="autoplay" muted></video>
          <video v-if="ckplayerObject.live" id="videoPlayer" class="video-js" muted></video>
          <div class="swiperVideo" v-if="!ckplayerObject.live">
            <swiper ref="videoSwiper" :options="swiperVideoOption"> 
              <div class="swiper-slide" v-for="(item,i) in teacherSwiper" :key="i">
                <img @click="goVideo(i)" :src="item" />
              </div>
              <!-- 分页器 -->
              <!-- <div class="swiper-pagination" slot="pagination"></div> -->
            </swiper>
          </div>
        </div>
        <p>
          <img @click="live800" src="/subject/newReport/wap/live800.png" alt="">
          <img @click="Login" src="/subject/newReport/login.png" alt="">
        </p>
        <img class="live800text" src="/subject/newReport/wap/live800text.png" alt="">
      </div>
      <div class="part part_2">
        <img src="/subject/newReport/title2.png" alt="">
        <img style="width:100%;" src="/subject/newReport/p2Banner.png" alt="">
        <p>
          <img @click="live800" src="/subject/newReport/wap/live800.png" alt="">
          <img @click="Login" src="/subject/newReport/login.png" alt="">
        </p>
        <img class="live800text" src="/subject/newReport/wap/live800text.png" alt="">
      </div>
      <div class="part part_3">
        <img src="/subject/newReport/title3.png" alt="">
        <img style="width:100%;" src="/subject/newReport/p3Banner.png" alt="">
        <p>
          <img @click="live800" src="/subject/newReport/wap/live800.png" alt="">
          <img @click="Login" src="/subject/newReport/login.png" alt="">
        </p>
        <img class="live800text" src="/subject/newReport/wap/live800text.png" alt="">
      </div>
      <div class="part part_4">
        <img src="/subject/newReport/title4.png" alt="">
        <img @click="downloadReport" class="download" src="/subject/newReport/wap/download.png" alt="">
        <div class="part4box">
          <div class="swiperBox">
            <swiper ref="mySwiper" :options="swiperOption"> 
              <div class="swiper-slide">
                <img src="/subject/newReport/1.png" />
              </div>
              <div class="swiper-slide">
                <img src="/subject/newReport/2.png" />
              </div>
              <div class="swiper-slide">
                <img src="/subject/newReport/3.png" />
              </div>
              <!-- 分页器 -->
              <!-- <div class="swiper-pagination" slot="pagination"></div> -->
            </swiper>
          </div>
        </div>
        <p>
          <img @click="live800" src="/subject/newReport/wap/live800.png" alt="">
          <img @click="Login" src="/subject/newReport/login.png" alt="">
        </p>
        <img class="live800text" src="/subject/newReport/wap/live800text.png" alt="">
      </div>
      <div class="part part_5">
        <img src="/subject/newReport/title5.png" alt="">
        <img style="width:100%;" src="/subject/newReport/p5Banner.png" alt="">
      </div>
    </div>
    <Contactus></Contactus>
  </div>
</template>

<script>
import Contactus from '../../Contactus.vue';
import {livingRoomNow} from '../../../../api/info'
import videojs from 'video.js'
export default {
  name:'newReportPC',
  components:{
    Contactus,
  },  
  data(){
    return{
      isVideo:'https://www.rlcvipltd.net/static/video/v-0320.mp4',
      // swiper 参数
      swiperOption: {
        slidesPerView: 3,
        // swiper图片之间的间隔
        spaceBetween: 0,
        centeredSlides: true,
        grabCursor:true,
        loop: true,
        preventClicks : true,
          //自动播放
        autoplay: {
          delay: 4000, //延迟播放下一张图片
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      swiperVideoOption: {
        slidesPerView: 5,
        // swiper图片之间的间隔
        spaceBetween: 20,
        centeredSlides: true,
        grabCursor:true,
        loop: true,
        preventClicks : true,
          //自动播放
        autoplay: {
          delay: 4000, //延迟播放下一张图片
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      screenWidth:null,
      scroll: Number,
      timer:null,
      myPlyer:null,
      options:null,
      teacherSwiper:[
        '/subject/newReport/11.png',
        '/subject/newReport/22.png',
        '/subject/newReport/33.png',
        '/subject/newReport/44.png',
        '/subject/newReport/55.png',
      ]
    }
  },
  computed:{
    ckplayerObject() {
      return this.$store.state.home.ckplayerObject
    },
    teacherPlayBack(){
      return this.$store.state.home.teacherPlayBack
    }
  },
  created(){
    // 传给App.vue,展示tabbar
    this.$emit('controlShow',true);
    this.init();
    this.timer = window.setInterval(() => {
      setTimeout(this.init(), 0)
    }, 1000*10)
    this.playBack();
  },
  beforeDestroy() {
    //在 beforeDestroy生命周期函数中销毁定时器
    clearInterval(this.timer);
    this.timer = null;
  },
  watch: {
    screenWidth: function (n) {
      if (n < 500) {
        this.$router.push({
          name: "newReportWap",
        });
      }
    },
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll);
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })()
    };
    // setTimeout(()=>{
    //   this.$nextTick(() => {
    //     this.initVideo(this.isVideo);
    //   })   
    // },500) 
  },
  // beforeDestroy() {
  //   this.myPlyer.dispose();
  // },  
  methods:{
    // 获取是否直播以及直播流
    init(){
      livingRoomNow({platid:1}).then(res=>{
        if(res.Status == 0 && res.content.length>0){
          this.$store.commit('home/set_ckplayerObject',{video: res.content[0].param.pcurl ,live: true})
          this.isVideo = res.content[0].param.pcurl;
          setTimeout(()=>{
            this.initVideo(this.isVideo);
          },500)
        }else{
          this.$store.commit('home/set_ckplayerObject',{video: 'https://www.rlcvipltd.net/static/video/v-0320.mp4' , live: false})
          this.isVideo = 'https://www.rlcvipltd.net/static/video/v-0320.mp4'; 
          // this.myPlyer.dispose();
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    live800(){
      window.open(
        this.$parent.live800UrlUc
      );
    },
    Login(){
      window.open("https://www.rlcvipltd.net/uc/login");
    },
    goBank(){
      window.location.href = window.location.origin;
    },
    // 初始化videojs内容
    initVideo(nowPlayVideoUrl) {
      // 这些options属性也可直接设置在video标签上，见 muted
      this.options = {
        autoplay: true, // 设置自动播放
        controls: true, // 显示播放的控件
        sources: [
        // 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
          {
          src: nowPlayVideoUrl,
          type: "application/x-mpegURL" // 告诉videojs,这是一个hls流
          }
        ]
      };
      // videojs的第一个参数表示的是，文档中video的id
      this.myPlyer = videojs("videoPlayer", this.options, function onPlayerReady() {
      console.log("onPlayerReady 中的this指的是：", this); // 这里的this是指Player,是由Videojs创建出来的实例
      console.log(this.myPlyer === this); // 这里返回的是true
      });
    },
    // 控制顶部导航栏的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if(this.scroll > 90 ){
        this.navBarFixed = true;
      }else{
        this.navBarFixed = false;
      }
    },
    // 下载报告书
    downloadReport(){
      window.open('https://oss.0790jiaxiao.com/Report/SpingSummerInvestment2023032801.pdf');
    },
    // 获取老师直播回放
    async playBack(){
      const data = {
        platid:1,
        sort:'ut,DESC',
        type:0,
        page:0,
        size:10,
      }
      await this.$store.dispatch('home/getPlayBack', data);
    },
    // 跳转视频回放
    goVideo(attr){
      // console.log(this.teacherPlayBack);
      switch (attr) {
        case 0:
          window.open(this.teacherPlayBack[4].fpath)
          break
        case 1:
          window.open(this.teacherPlayBack[3].fpath)
          break
        case 2:
          window.open(this.teacherPlayBack[2].fpath)
          break
        case 3:
          window.open(this.teacherPlayBack[1].fpath)
          break
        case 4:
          window.open(this.teacherPlayBack[0].fpath)
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .box{
    width: 100%;
    height: auto;
    max-width: 1920px;
    min-width: 1640px;
    .bannerBox{
      position: relative;
      .banner{
        width: 100%;
        display: block;
      }
      .logo{
        position: absolute;
        top: 30px;
        left: 50px;
        cursor: pointer;
      }
    }
    .content{
      width: 100%;
      height: 7130px;
      background: url('/subject/newReport/background.png') no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 40px 0;
      .part{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        p{
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: 3.5%;
          left: 0;
          box-sizing: border-box;
          img{
            width: 350px;
            margin: 0 30px;
            cursor: pointer;
          }
        }
        .live800text{
          width: 268px;
          position: absolute;
          bottom: 1%;
          left: 32%;
        }
      }
      .part_1{
        .p1_box{
          width: 100%;
          height: 1172px;
          background: url('/subject/newReport/p1Banner.png') no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          position: relative;
          .video{
            width: 1363px;
            height: 660px;
          }
          .swiperVideo{
            width: 1340px;
            height: 190px;
            position: absolute;
            bottom: 40%;
            left: 50%;
            transform: translate(-50%,-50%);
            .swiper-container{
              width: 100%;
              height: 100%;
              .swiper-wrapper{
                .swiper-slide{
                  img{
                    width: 246px;
                    object-fit: cover;
                  }
                  img:hover{
                    border: 2px solid #fff;
                    border-radius: 10px;
                  }
                }
              }
            }
          }
        }
      }
      .part_2{
        width: 100%;
        margin-top: 40px;
        p{
          bottom: 4%;
        }
      }
      .part_3{
        width: 100%;
        margin-top: 40px;
        p{
          bottom: 6%;
        }
        .live800text{
          bottom: 2.2%;
        }
      }
      .part_4{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 40px;
        .part4box{
          width: 100%;
          height: 1735px;
          background: url('/subject/newReport/p4Banner.png') no-repeat;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          .swiperBox{
            width: 65%;
            margin: 450px auto 0;
            .swiper-container{
              width: 100%;
              height: 620px;
              .swiper-wrapper{
                .swiper-slide{
                  img{
                    width: 358px;
                    height: 506px;
                    object-fit: cover;
                  }
                  /* Center slide text vertically */
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: -webkit-flex;
                  display: flex;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  -webkit-justify-content: center;
                  justify-content: center;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  -webkit-align-items: center;
                  align-items: center;
                  transition: 300ms;
                  transform: scale(1);
                }
                .swiper-slide-active,.swiper-slide-duplicate-active{
                  transform: scale(1.2);
                }
              }
            }
          }
        }
        p{
          bottom: 3%;
        }
        .download{
          position: absolute;
          cursor: pointer;
          top: 25%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
      .part_5{
        margin-top: 40px;
      }
    }
  }
  /deep/ .videoPlayer-dimensions{
    width: 1363px;
    height: 660px;
  }
</style>